import commons from './lang/commons.json'
import privacy from './lang/privacy.json'
import attributes from './lang/attributes.json'
import roles from './lang/roles.json'
import router from './lang/router.json'
import pages from './lang/pages.json'
import apartments from './lang/apartments.json'
import social from './lang/social.json'
import contacts from './lang/contacts.json'
import cookie from './lang/cookie.json'

const lang = {
     commons,
     privacy,
     attributes,
     router,
     roles,
     social,
     pages,
     apartments,
     cookie,
     contacts
}
export default lang