<template>
  <v-container fluid dark class="d-block">
    <v-container dark>
      <v-row class="light--text">
        <v-col cols="12" md="6" >
            <v-row>
              <v-col cols="12">
                    <h2 class="text-capitalize mb-4">
                      <v-avatar>
                        <v-img contain :src="require('@/assets/dada.jpg')" class="logo mr-3" :alt="title"></v-img>
                      </v-avatar>
                    {{$t('commons.contacts')}}
                    </h2>
                    <p>
                        <template v-for="(item) in contacts" >
                              <div :key="item.name">
                                <v-btn text color="light" target="_blank" :href="item.href">
                                    <v-icon class="mr-2">{{item.icon}}</v-icon> {{item.name}}
                                </v-btn> <small>{{item.value}}</small>
                              <br>
                              </div>
                        </template>
                    </p>
              </v-col>
              <v-col cols="12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.475490790407!2d11.694198088147235!3d46.43082269868889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47786912c30332a5%3A0xb09bd68ecb918abb!2zQ2lhc2EgTsO8!5e0!3m2!1sit!2sit!4v1602118279622!5m2!1sit!2sit" style="width: 100%; height: 350px; border:0;" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6" class="order-3 order-md-2" v-if="!loading">
                  <v-card dark class="small">
                    <!-- <json-debug :objs="[reviews]"></json-debug> -->
                    <v-card-text primary-title class="text-center">
                      <a :href="$t('contacts.address.href')" target="_blank" rel="noopener noreferrer" class="nolink">
                        <v-rating
                          background-color="light"
                          color="secondary"
                          readonly
                          hover
                          length="5"
                          size="25"
                          :value="rating"
                        ></v-rating> ({{user_ratings_total}})
                      </a>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list two-line>
                        <v-list-item-group dense>
                        <v-list-item v-for="review in reviews" :key="review.time" :href="review.author_url" target="_blank">
                          <v-list-item-avatar>
                            <v-img :src="review.profile_photo_url"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content >
                            <v-list-item-subtitle> <v-rating color="secondary" readonly x-small :value="review.rating"></v-rating></v-list-item-subtitle>
                            <v-list-item-title class="text-capitalize">{{review.author_name}}</v-list-item-title>
                            <small>{{review.text}}<br><small>{{review.relative_time_description}}</small></small>
                          </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
        </v-col>
       
        
        <v-col cols="12" class="order-4">
          <v-divider></v-divider>
          <p class="my-4"> {{ new Date().getFullYear() }} — <strong>Ciasa Nü</strong></p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  
</template>

<script>
import {mapGetters} from "vuex";

  export default {
    name: 'HeroContact',
    computed: {
     ...mapGetters({
          loading: 'loading/loading',
          reviews: 'gmaps/reviews',
          rating: 'gmaps/rating',
          user_ratings_total: 'gmaps/user_ratings_total'
      }),
    },
    data: () => ({
      title: process.env.VUE_APP_BASE_NAME,
      contacts: {},
    }),
    created() {
        this.contacts = this.$i18n.t('contacts')
        if(!this.rating){
          this.$store.dispatch('gmaps/getData')
        }
    }
  }
</script>