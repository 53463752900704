import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from "./plugins/i18n";
import moment from 'moment';
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";
import logger from './plugins/logger';
import VueScrollTo from 'vue-scrollto';
require('./plugins/general.scss')


import AxiosPlugin from 'vue-axios-cors';
 
Vue.use(AxiosPlugin)
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

moment.locale(i18n.locale)
Vue.prototype.moment = moment

Vue.component('snack-bars', () => import('@/components/Commons/SnackBars'))
Vue.component('datepicker', () => import('@/components/Commons/formValues/datepicker'))
Vue.component('timepicker', () => import('@/components/Commons/formValues/timepicker'))
Vue.component('simple-table', () => import('@/components/Commons/SimpleTable') )
Vue.component('json-debug', () => import('@/components/Commons/ObjDebug'))
Vue.component('paginated-table', () => import('@/components/Commons/PaginatedTable'))
Vue.component('dialog-fullscreen', () => import('@/components/Commons/DialogFullScreen'))
Vue.component('dialog-simple', () => import('@/components/Commons/DialogSimple'))
Vue.component('breadcrumbs', () => import('@/components/Layout/Menu/breadcrumbs'))

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GA_TRACK_ID, 
    params: {
      send_page_view: true
    }
  }
});
Vue.use(VueScrollTo)

new Vue({
  router,
  i18n,
  store,
  moment,
  vuetify,
  logger,
  render: h => h(App)
}).$mount('#app')
