<template>
  <div v-if="languages.lengh > 1">
    <v-menu offset-y rounded="0"  bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="light"
          dark
          icon
          x-small
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-avatar size="24">
            <v-img :src="require('@/assets/flags/'+language+'.png')" :alt="language+' flag'" ></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="language in languages"
          :key="language"
          @click="changeLocale(language)"
        >
          <v-list-item-title>
            <v-avatar
              size="15"
              class="me-3"
            >
               <v-img :src="require('@/assets/flags/'+language+'.png')" :alt="language+' flag'"></v-img>
            </v-avatar>
            {{$t('commons.languages.'+language)}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
     data: () => ({
          menu: false,
          language: null,
          languages: null,
     }),
     beforeMount(){
         this.language = this.$i18n.locale
         this.languages =  this.$i18n.availableLocales
     },
     methods:{
          changeLocale(item){
               console.log(item)
               this.language = item
                this.$i18n.locale = this.language
                localStorage.setItem('language', this.language) 
          }
     }
}
</script>