import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/plugins/i18n";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    alias: '/home',
    component: () => import('../views/Home.vue'),
    meta:{
      hidden: false
    }
  },
  {
    path: '/appartamenti',
    name: 'apartments.index',
    redirect: { name:'apartments.list'},
    alias: '/apartments',
    meta:{
      hidden: false
    },
    component: () => import(/* webpackChunkName: "apartments" */ '@/views/apartments/dash.vue'),
        children:[
      {
        path:'',
        name: "apartments.list",
        component: () => import(/* webpackChunkName: "apartments" */ '@/views/apartments/List.vue'),
        meta:{
          hidden: false
        }
      },
      {
        path:':slug',
        params: 'slug',
        name: "apartments.single",
        component: () => import(/* webpackChunkName: "apartments" */ '@/views/apartments/Single.vue'),
        meta:{
          hidden: false
        }
      }
    ]
  },
  {
    path: '/contatti',
    alias: '/contacts',
    name: 'contacts',
    component: () => import('../views/pages/Contacts.vue'),
    meta:{
      hidden: false
    }
  },
  {
    path: '/foto-gallery',
    name: 'gallery',
    component: () => import('../views/pages/FotoGallery.vue'),
    meta:{
      hidden: false
    }
  },
  {
    path: '/val-di-fassa',
    alias: '/about-val-di-fassa',
    name: 'val-di-fassa',
    component: () => import('../views/pages/ValdiFassa.vue'),
    meta:{
      hidden: false
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: '404',
    component: () => import('../views/pages/404.vue'),
    meta:{
      hidden: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {


  console.log('to',to)
  console.log('from',from)

  window.scrollTo(0, 0)
  
  let parents = to.matched
  console.log('parents',parents);
  to.meta.breadCrumbs = []
  to.meta.breadCrumbs.push({text:'home','href':'/'})
  to.meta.analytics = to.name

  document.title = process.env.VUE_APP_BASE_NAME + ' - ' + i18n.t('router.'+to.name+'.title');
  document.description = i18n.t('router.'+to.name+'.description');

  if(parents.length >= 1){
    var parent = parents[0];
    to.meta.breadCrumbs.push({text:parent.name, href:parent.path})

    document.title = process.env.VUE_APP_BASE_NAME + ' - ' + i18n.t('router.'+parent.name+'.title');

    if(parents.length >= 2){
      if(to.params){
        if(Object.keys(to.params).length){
          // console.log('to.params',to.params)
          to.meta.breadCrumbs.push({text:to.name+'.'+to.params.slug, href:''+to.path})
          document.title = document.title + ' - ' + i18n.t('router.'+to.name+'.'+to.params.slug+'.title');
          document.description = i18n.t('router.'+to.name+'.'+to.params.slug+'.description');
        }
      }

    }
  }  

  var metaThemeColor = document.createElement('meta');
    metaThemeColor.setAttribute('name', 'theme-color');
    metaThemeColor.setAttribute('id', 'theme-color');
    metaThemeColor.content = process.env.VUE_APP_COLOR_PRIMARY;

  document.querySelector('meta[name="theme-color"]').remove();
  document.getElementsByTagName('head')[0].appendChild(metaThemeColor);

  

  next()

})

export default router
