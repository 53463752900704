<template>
  <v-app id="app">
    <header-menu />
    <v-main>
      <router-view/>
    </v-main>
    <footer-menu />
    <snack-bar></snack-bar>
  </v-app>
</template>

<script>
import Header from "@/components/Layout/Menu/Header";
import Footer from "@/components/Layout/Menu/Footer";
import snackBars from "@/components/Commons/SnackBars"

export default {
  name: 'Home',
  components: {
    'snack-bar':snackBars,
    'header-menu':Header,
    'footer-menu':Footer
  },
  data: () => ({
    title: process.env.VUE_APP_BASE_NAME
  })
}
</script>
<style scoped>
.v-main{
  /* min-height: 99vh; */
}
</style>