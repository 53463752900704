import Vue from 'vue'
import Vuex from 'vuex'

import gmaps from "./modules/gmaps"
import loading from "./modules/loading"
import apartments from "./modules/apartments"
import photos from "./modules/photos"
import snackbars from './modules/snackbars'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    gmaps,
    loading,
    snackbars,
    apartments,
    photos
  }
})
