<template>
<div>
  <v-navigation-drawer
            v-model="drawer"
            fixed
            dark
            top
            temporary
            class="text-uppercase d-block d-lg-none menumobile"
          >
           <div class="my-3">
             <v-img contain :src="require('@/assets/icon-white.svg')" class="logo" :alt="title"></v-img>
           </div>
           <v-divider></v-divider>
            <v-list
              flat
            >
              <v-list-item-group
                active-class="active-menu-btn"
              >
              <v-list-item
               v-for="item in menu"
               :key="item.name" 
               :to="item.path"
               rounded='0'
               
               tile
              >
                <v-list-item-title>{{$t('router.'+item.name+'.title')}}</v-list-item-title>
              </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider></v-divider>
            <v-spacer></v-spacer>
             
              <v-list>
                <div class="py-3 px-4">
                 <choose-lang/>
                </div>
              </v-list>

  </v-navigation-drawer>
  <v-container fluid class="menu py-0">
    <v-container >
      <v-row no-gutters align="center">
        <v-col cols="2">
          <v-btn icon plain large :to="{name:'home'}" class="logo">
            <v-img contain :src="require('@/assets/icon-white.svg')" class="logo" :alt="title"></v-img>
          </v-btn>
        </v-col>
        <v-col cols="10" class="text-right text-uppercase d-block d-lg-none">
          <v-app-bar-nav-icon dark @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="9" class="text-right text-uppercase d-none d-lg-block">
          <template  >
            <v-btn 
              dark 
              v-for="item in menu"
              tile 
              text 
              active-class="active-menu-btn"
              :key="item.name" 
              :to="item.path"
            >
            {{$t('router.'+item.name+'.title')}}
            </v-btn>
          </template>
        </v-col>
        <v-col cols="1" class="text-right text-uppercase d-none d-lg-block">
          <choose-lang/>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear
      v-if="loading"
      :color="(loading) ? 'primary' : 'grey'"
      :indeterminate="loading"
    ></v-progress-linear>
  </v-container>
</div>
</template>

<script>
import ChooseLang from '../Locales/ChooseLang.vue'
import {mapGetters} from "vuex";
export default {
  components: { 'choose-lang':ChooseLang },
  computed: {
      ...mapGetters({
            loading: 'loading/loading',
        }),
    },
     data: () => ({
          title: process.env.VUE_APP_BASE_NAME,
          drawer: null,
          menu: [ ],
     }),
     created() {
        this.$router.options.routes.forEach(route => {
            if(route.meta.hidden){
              console.log('is_hidden',route)
            }else{
              this.menu.push(route)
            }
        })
    }
}
</script>

<style scoped>
.menu{
  background-color: rgba(0,0,0,0.5);
  box-shadow: 1px 5px 10px rgba(0,0,0,0.5);
  position: fixed;
  margin-bottom: 0px;
  z-index: 10;
}

.menumobile{
  z-index: 11;
}

.logo{
  max-height: 55px;
}
.active-menu-btn{
  border-bottom: 1px solid;
}
</style>