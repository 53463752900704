import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
          path: "481f92c6c3950bd41ca3",
          list: [],
          single: null,
          errors: {},
     },
     getters:{
          list: state => state.list,
          single: state => state.single
     },
     mutations:{
          setList(state,list){
               console.log('mutation setList',list);
               state.list = list
          },
          setSingle(state,single){
               console.log('mutation setSingle',single);
               state.single = single
          },
          error(state,err) {
               state.assets = null,
               state.errors = err,
               console.log('mutation error',err);
          },
     },
     actions:{
          getData({commit, state},data){
               return new Promise((resolve, reject) => {
                    console.log('GET data')
                    apiClient.get(state.path, data).then(resp => {
                         console.log('GET data', resp.data)
                         commit('setList', resp.data.data);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('GET LIST ERROR', err)
                         commit('error', err);
                         reject(err);
                    });
               })
          },
          setSingle({commit}, data){
               commit('setSingle', data);
          },
          getSingle({commit, state}, data){

          
               state.list.find(obj => {
                    if(obj.slug === data){
                         commit('setSingle', obj);
                    }
               })
              
          }
     }
}
