/* https://maps.googleapis.com/maps/api/place/details/json?cid=12726001080854481595&key=AIzaSyD0kNsvUld85txe-R5YgE6YL5am3YIfV40 */
/* https://maps.googleapis.com/maps/api/place/details/json?cid=12726001080854481595&key=AIzaSyD7uoSeDq82ZLzUnZrlY57JrkCVqZ5zch8 */

const qs = require('qs');
import apiClient from '@/plugins/apiClient'
import fake from './fake.json';

export default {
     namespaced: true,
     state:{
          path: "5d2d214fec0a49641868",
          url: "https://maps.googleapis.com/maps/api/place/details/json",
          fake: fake,
          params: {
               cid : "12726001080854481595",
               key:"AIzaSyDWoeP6OzejdxyrQOW0VWPKKKLxU4dnasA",
          },
          reviews: [],
          user_ratings_total: null,
          rating: null,
          photos: [],
          errors: null,
     },
     getters:{
          reviews: state => state.reviews,
          rating: state => state.rating,
          user_ratings_total: state => state.user_ratings_total,
     },
     mutations:{
          setErrors(state,resp){
               console.log('mutation setErrors',resp);
               state.errors = resp
          },
          
          setSuccess(state,resp){
               console.log('mutation setSuccess gmaps',resp);
               state.rating = resp.result.rating
               state.reviews = resp.result.reviews
               state.user_ratings_total = resp.result.user_ratings_total
          }
     },
     actions:{
      getData({commit, state}){
         
         commit('setSuccess',state.fake)
               
    },
    getDataMaps({commit,state}){
               commit('setSuccess',state.fake)
               
               var params = (state.params) ? qs.stringify(state.params, { arrayFormat: 'indices' }) : null
               var fullUrl = state.url +'?'+ params
               
               return new Promise((resolve, reject) => {
                  console.log('GET data')
                  apiClient.get(fullUrl).then(resp => {
                       console.log('GET data', resp.data)
                       commit('setSuccess', resp.data);
                       resolve(resp);
                  })
                  .catch(err => {
                       console.log('GET LIST ERROR', err)
                       commit('setErrors', err);
                       reject(err);
                  });
             })
          }
     }
}
