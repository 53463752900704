import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
     theme: {
          themes: {
             light: {
               primary: process.env.VUE_APP_COLOR_PRIMARY,
               secondary: "#ceae57",
               green: "#53a04d",
               blue: "#1d90a2",
               red: "#ca4f46",
               light: "#f3f3f3",
               grey: "#6c757d",
               dark: "#343a3f",
             },
          },
     },
});
